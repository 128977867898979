<template>
    <div style="width:100%;float:left;" class="float-left">
        <!-- <CCard>
          <CCardBody>
            <div class="col-12 float-left text-center p-3">
                <select style="width:200px;margin-left:auto;margin-right:auto;" @change="getOrders()" class="form-control" v-model="filter">
                    <option value="All">All</option>
                    <option value="needs_entered">Needs Entered</option>
                    <option value="closed">Closed</option>
                </select>
            </div>
            <table class="table" v-if="data">
                <thead>
                    <tr>
                        <th>Order#</th>
                        <th>Cust#</th>
                        <th>PO</th>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="value in data" @click="$router.push('/order_display/' + value.token)">
                        <td>{{value.id}}</td>
                        <td>{{value.customer_number}}</td>
                        <td>{{value.po_number}}</td>
                        <td>{{value.user_name}}</td>
                        <td>{{value.email}}</td>
                    </tr>
                </tbody>
            </table>
          </CCardBody>
        </CCard> -->
        <CCard>
          <CCardHeader>
            My Orders
          </CCardHeader>
          <CCardBody>
            <CAlert
              :show.sync="dismissCountDown"
              color="primary"
              fade
            >
              ({{dismissCountDown}}) {{ message }}
            </CAlert>
            <CDataTable
              :items="items"
              :fields="fields"
              :items-per-page="10"
              pagination
              sorter
              column-filter
              table-filter
            >
              <template #styles="{item}">
                <td>
                  <span v-for="val in item.order_items">{{val.pai_style}}, </span>
                </td>
              </template>
              <template #sizes="{item}">
                <td>
                  <span v-for="val in item.order_items">{{val.size}}, </span>
                </td>
              </template>
              <template #show="{item}">
                <td>
                  <CButton size="sm" color="primary" @click="goTo( item )">View</CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
    </div>
</template>
<script>
var methods = {}

methods.getOrders = function () {
    // const postData = {
    //     filter: this.filter
    // }
    axios.post(this.$apiAdress + '/api/getMyOrders?token=' + window.localStorage.getItem('api_token')).then(response => {
        if (response.status === 200) {
            console.log(response)
            this.items = response.data
        }
    })
}

methods.goTo = function (item) {
  this.$router.push('/order_display/' + item.token)
}

export default {
    name: "OrdersTable",
    data: function () {
    	return {
        data: null,
        filter: 'All',
        items: [],
        fields: ['id', 'po_number', 'styles', 'sizes', 'show'],
        currentPage: 1,
        perPage: 5,
        totalRows: 0,
        you: null,
        message: '',
        showMessage: false,
        dismissSecs: 7,
        dismissCountDown: 0,
    	}
    },
    paginationProps: {
      align: 'center',
      doubleArrows: false,
      previousButtonHtml: 'prev',
      nextButtonHtml: 'next'
    },
    mounted: function() {
        this.getOrders()
    },
    methods: methods,
    destroyed: function() {

    }
}
</script>
